// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//core
import { Patient } from './patient';
import { OperationResult } from '../core/operation-result';

@Injectable()
export class PatientService extends BaseService {

  private readonly apiUrl = 'api/patient';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPatient(id: number): Observable<Patient> {

    return this.http.get<Patient>(`${this.apiUrl}/${id}`);
  }

  public getUserPatients(userid: string): Observable<Patient[]> {

    return this.http.get<Patient[]>(`${this.apiUrl}/user-patients/${userid}`);
  }

  public downloadPdfFile(id: number): Observable<Blob> {

    return this.http.get(`${this.apiUrl}/file/${id}`, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public putPatient(patient: Patient): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}`, patient, { headers: this.PutPostHeaders });
  }

  public postPatient(patient: Patient): Observable<Patient> {

    return this.http.post<Patient>(`${this.apiUrl}`, patient, { headers: this.PutPostHeaders });
  }

  public postImagesPatient(formData: FormData): Observable<OperationResult> {

    return this.http.post<OperationResult>(`${this.apiUrl}/upload`, formData);
  }

  public deletePatient(patientId: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${patientId}`);
  }

}
