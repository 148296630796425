import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//documentacion de interceptor.
// https://medium.com/@insomniocode/angular-autenticaci%C3%B3n-usando-interceptors-a26c167270f4

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private readonly router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return next.handle(req).pipe(
      catchError((err: String) => {
        console.log(err);
        if (err === 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.router.navigateByUrl('/login');
        }

        return throwError(err);

      })

    //return next.handle(req).pipe(
    //  catchError((err: HttpErrorResponse) => {

    //    if (err.status === 401 || err.status === 403) {
    //      localStorage.removeItem('token');
    //      localStorage.removeItem('user');
    //      this.router.navigateByUrl('/login');
    //    }

    //    return throwError(err);

    //  })

    );
  }
}
