// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { catchError, map } from 'rxjs/operators';

import { BaseService } from '../core/base.service';

//core
import { CovidData } from './covid-data';
import { OperationResult } from '../core/operation-result';

@Injectable()
export class CovidDataService extends BaseService {

  private readonly apiUrl = 'api/coviddata';  

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public postCovidData(covidData: CovidData): Observable<CovidData> {

    return this.http.post<CovidData>(`${this.apiUrl}`, covidData, { headers: this.PutPostHeaders });
  }

  //public postImagesCovidData(formData: FormData): Observable<OperationResult> {

  //  return this.http.post<OperationResult>(`${this.apiUrl}/upload`, formData);
  //}

  //public postImagesCovidData(formData: FormData): Observable<any> {

  //  return this.http.post(`${this.apiUrl}/upload`, formData)
  //    .pipe(
  //      map((res: any) => res.json()),
  //      catchError(this.handleError));
  //}


  //public postImagesCovidData2(fileMPC: File, fileMO: File, covidDataId: string): Promise<any> {

  //  return new Promise((resolve, reject) => {

  //    let fileUploads: FilesUpload[] = [];
  //    let fileUploadMPC: FilesUpload;
  //    let fileUploadMO: FilesUpload;

  //    if (fileMPC !== undefined) {
  //      fileUploadMPC = { file: fileMPC, type: 'MPC' }
  //      fileUploads.push(fileUploadMPC);
  //    }
  //    if (fileMO !== undefined) {
  //      fileUploadMO = { file: fileMO, type: 'MO' }
  //      fileUploads.push(fileUploadMO);
  //    }
           
  //    let formData = new FormData();
  //    let xhr = new XMLHttpRequest();

  //    for (const row of fileUploads) {
  //      formData.append(row.file.name, row.file);
  //      formData.append(`fileType${row.file.name}`, row.type);
  //      formData.append('covidDataId', covidDataId);
  //    }   

  //    xhr.onreadystatechange = function () {

  //      if (xhr.readyState === 4) {

  //        if (xhr.status === 200) {
  //          console.log('Imagen subida');
  //          resolve('Imagen subida');
  //        } else {
  //          console.log('Fallo la subida');
  //          reject(xhr.response);
  //        }

  //      }
  //    };

  //    xhr.open('POST', `${this.apiUrl}/upload`, true);
  //    xhr.send(formData);

  //  });
  //}

  //private handleError(error) {

  //  let errorMessage = '';

  //  if (error.error instanceof ErrorEvent) {
  //    // client-side error
  //    errorMessage = `Error: ${error.error.message}`;
  //  } else {
  //    // server-side error
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }

  //  console.log(errorMessage);
  //  return Observable.throw(errorMessage);
  //}

}
