//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

//Services
import { SummaryDataService } from '../shared/summary-data.service';

//Primeng
import { Message } from 'primeng/api';

@Component({
  selector: 'app-form-submit-successfully',
  templateUrl: './form-submit-successfully.component.html',
})
export class FormSubmitSuccessfullyComponent implements OnInit {

  public confirmationNumber = '';
  public returnImagesValueMO = '';
  public successMO = false;
  public returnImagesValueMPC = '';
  public successMPC = false;
  public returnImagesValuePId = '';
  public successPId = false;
  public msgsMO: Message[] = [];
  public msgsMPC: Message[] = [];
  public msgsPId: Message[] = [];

  public constructor(private router: Router,
    private route: ActivatedRoute,
    private readonly summaryDataService: SummaryDataService) { }

  public ngOnInit(): void {

    this.summaryDataService.shareConfirmationNumber.subscribe(x => this.confirmationNumber = x);

    this.summaryDataService.shareReturnValueMO.subscribe(x => this.returnImagesValueMO = x);

    this.summaryDataService.shareSuccessMO.subscribe(x => this.successMO = x);

    this.summaryDataService.shareReturnValueMPC.subscribe(x => this.returnImagesValueMPC = x);

    this.summaryDataService.shareSuccessMPC.subscribe(x => this.successMPC = x);

    this.summaryDataService.shareReturnValuePId.subscribe(x => this.returnImagesValuePId = x);

    this.summaryDataService.shareSuccessPId.subscribe(x => this.successPId = x);

    if (this.returnImagesValueMO.length > 0) {
      if (this.successMO === true) {
        this.showMessageMO('success', this.returnImagesValueMO);        
      } else {
        this.showMessageMO('error', this.returnImagesValueMO);
      }
    }

    if (this.returnImagesValueMPC.length > 0) {
      if (this.successMPC === true) {
        this.showMessageMPC('success', this.returnImagesValueMPC);
      } else {
        this.showMessageMPC('error', this.returnImagesValueMPC);
      }
    }

    if (this.returnImagesValuePId.length > 0) {
      if (this.successPId === true) {
        this.showMessagePId('success', this.returnImagesValuePId);
      } else {
        this.showMessagePId('error', this.returnImagesValuePId);
      }
    }

    //this.route.params.forEach((params: Params) => {
    //  this.confirmationNumber = params['confirmationNumber'];
    //  this.confirmationNumber = params['returnImagesValue'];
    //});
  }

  private showMessageMO(severity: string, detail: string): void {

    const msg: Message = {

      severity,
      detail
    };

    this.msgsMO.push(msg);
  }

  private showMessageMPC(severity: string, detail: string): void {

    const msg: Message = {

      severity,
      detail
    };

    this.msgsMPC.push(msg);
  }

  private showMessagePId(severity: string, detail: string): void {

    const msg: Message = {

      severity,
      detail
    };

    this.msgsPId.push(msg);
  }
}
