import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

//services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account/account.service';
import { PostalCodeService } from '../postal-code/postal-code.service';
import { LaboratoryService } from '../laboratory/laboratory.service';
import { SymptomService } from '../symptom/symptom.service';
import { ReCaptchaService } from '../re-captcha/re-captcha.service';
import { CovidDataService } from './covid-data.service';
import { ProcedureService } from '../procedure/procedure.service';
import { SummaryDataService } from '../shared/summary-data.service';
import { AutoLoadPatientDataService } from '../patient/auto-load-patient-data.service';
import { PatientService } from '../patient/patient.service';

//core
import { CovidData } from './covid-data';
import { PostalCode } from '../postal-code/postal-code';
import { OperationResult } from '../core/operation-result';
import { Laboratory } from '../laboratory/laboratory';
import { Symptom } from '../symptom/symptom';
import { Procedure } from '../procedure/procedure';
import { CaptchaParameter } from '../re-captcha/captcha-parameter';
import { ResponseControlCaptcha } from '../re-captcha/response-control-captcha';
import { Patient } from '../patient/patient';

// Common Codes
import { ReCaptchaKeys } from '../re-captcha/re-captcha-keys';

//Primeng
import { Message, MessageService } from 'primeng/api';

interface FilesUpload {
  file: File;
  type: string;
}

interface Gender {
  label: string;
  value: string;
}

interface Carriers {
  label: string;
  value: string;
}

@Component({
  selector: 'app-covid-data',
  templateUrl: './covid-data.component.html',
  providers: [MessageService]
})
export class CovidDataComponent extends OnDestroyMixin implements OnInit {

  public covidData: CovidData;
  public selectPostalCode: PostalCode;
  public postalCodeSearchResults: PostalCode[];
  public selectMailingPostalCode: PostalCode;
  public laboratories: Laboratory[] = [];
  public selectLaboratory: Laboratory;
  public procedures: Procedure[] = [];
  public selectedProcedures: Procedure[] = [];
  public allProcedures: string = '';
  //public symptoms: Symptom[] = [];
  //public selectedSymptoms: Symptom[] = [];
  //public allSymptoms: string = '';
  //public ckeSymptoms = false;
  public msgs: Message[] = [];
  public labInfo: string;
  public genderList: Gender[] = [];
  public selectGender: Gender;
  //public imgMedicalPlanCard: any;
  //public imgMedicalOrder: any;
  //public imgPatientId: any;
  public captchaParameter: CaptchaParameter;
  public responseControlCaptch: ResponseControlCaptcha;
  public siteKey: string;
  public isValidCaptcha = false;
  public progressSpinner = false;
  public confirmationNumber = '';
  public proceduresValidator = false;
  //public symptomsPresentDateValidator = true;
  //public symptomsPresentValidator = true;
  public emailValidator = true;
  public emailPasswordValidator = true;
  //public pregnantGestationWeeksValidator = true;
  //public pregnantEstimatedBirthDateValidator = true;
  //public pregnantObstetricianValidator = true;
  //public medicalOrderValidator = true;
  //public medicalPlanCardValidator = true;
  public minDate = new Date();
  public maxDate = new Date();
  //public minDatePregnant = new Date();
  //public maxDatePregnant = new Date();
  //public ckePregnant = false;
  //public ckeSeguroMedico = false;
  public patient: Patient = null;
  //public isPdfFile = false;
  public patientId = 0;
  public carrierList: Gender[] = [];
  public selectCarrier: Gender;
  @ViewChild('covidform', { static: false }) public covidform: NgForm;
  @ViewChild('captcha', { static: false }) captcha;
  //@ViewChild('medicalPlanCardFileName', { read: ElementRef, static: false }) medicalPlanCardFileName;
  //@ViewChild('medicalOrderFileName', { read: ElementRef, static: false }) medicalOrderFileName;

  public constructor(private readonly covidDataService: CovidDataService,
      private readonly postalCodeService: PostalCodeService,
      private readonly laboratoryService: LaboratoryService,
      private readonly accountService: AccountService,
      private readonly procedureService: ProcedureService,
      private readonly patientService: PatientService,
      private readonly reCaptchaService: ReCaptchaService,
      private readonly symptomService: SymptomService,
      private readonly messageService: MessageService,
      private readonly router: Router,
      private readonly summaryDataService: SummaryDataService,
      private readonly autoLoadPatientDataService: AutoLoadPatientDataService,) {
      super();

    this.siteKey = ReCaptchaKeys.websitekey;

    this.genderList = [
      { label: 'Masculino', value: 'Masculino' },
      { label: 'Femenino', value: 'Femenino' },
      { label: 'Otros', value: 'Otros' }
    ];

    this.carrierList = [
      { label: 'AT&T', value: '@txt.att.net' },
      { label: 'Boost Mobile', value: '@myboostmobile.com' },
      { label: 'Claro', value: '@vtexto.com' },
      { label: 'Sprint', value: '@messaging.sprintpcs.com' },
      { label: 'T-Mobile', value: '@tmomail.net' },
      { label: 'Verizon', value: '@vzwpix.com' },
      { label: 'Virgin Mobile', value: '@vmpix.com' }
    ];
    
  }

  public ngOnInit(): void {

    this.covidData = {} as CovidData;

    this.getLaboratories();

    this.maxDate.setDate(this.minDate.getDate() + 25);
    //this.maxDatePregnant.setDate(this.minDatePregnant.getDate() + 280);

    this.autoLoadPatientDataService.sharePatientData.subscribe(x => this.patient = x);

    if (this.patient !== null) {

      this.loadCovid19Data(this.patient);
    }

  }

  public onSubmit(): void {

    if (this.isValidCaptcha === true) {      

      this.progressSpinner = true;

      this.covidData.physicalCity = this.selectPostalCode.city;
      this.covidData.mailingAddressCity = this.selectMailingPostalCode.city;
      
      if (this.allProcedures.length > 0) {
        this.covidData.orderProcedure = this.allProcedures;
      }

      //const birthday = moment(this.covidData.birthDate);
      //moment().format('MM/DD/YYYY');
      //console.log(this.covidData.birthDate);

      this.covidData.age = this.ageFromDateOfBirthday(this.covidData.birthDate);

      this.covidDataService.postCovidData(this.covidData)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (data) => {

            this.covidData = data; 

            this.confirmationNumber = `${this.covidData.id}-${this.covidData.labLicenseNumber}`;
           
          },
          (error: string) => {

            this.progressSpinner = false;
            this.showMessage('error', error);            
          },
          () => {

            this.progressSpinner = false;
            this.summaryDataService.setConfirmationNumber(this.confirmationNumber);

            if (this.confirmationNumber !== '') {
              this.router.navigate(['/form-submit-successfully']);
            }
          });
      
    } else {

      this.showMessage('warn', 'Haga clic en la casilla de verificación Captcha.');   
    }
  }

  public getLaboratories(): void {

    this.laboratoryService.getLaboratories()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.laboratories = data;
      },
      (error: any) => {

        console.log(error);

        },
        () => {

          this.getProcedures();

        });
  }

  public getProcedures(): void {

    this.procedureService.getProcedures()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.procedures = data;
      },
        (error: any) => {

          console.log(error);

        });
  }  

  // search  postal codes
  public searchPostalCodes(event: any): void {

    this.postalCodeService.searchPostalCodes(event.query)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.postalCodeSearchResults = data;
      });
  }

  // set  postal code information
  public onPostalCodeSelection(postalCode: PostalCode): void {

    this.selectPostalCode = postalCode;
    this.covidData.physicalAddressState = postalCode.state;
    this.covidData.physicalZipZode = postalCode.code;
  }

  // clear postal code information
  public clearSelectedPostalCode(): void {

    this.selectPostalCode = undefined;
  }

  // set mailing  postal code information
  public onMailingPostalCodeSelection(postalCode: PostalCode): void {

    this.selectMailingPostalCode = postalCode;
    this.covidData.mailingAddressState = postalCode.state;
    this.covidData.mailingAddressZipZode = postalCode.code;
  }

  // clear postal code information
  public clearMailingSelectedPostalCode(): void {

    this.selectMailingPostalCode = undefined;
  }

  public onChangeLaboratory(event: any): void {

    this.covidData.labLicenseNumber = event.licenseNumber;
    this.labInfo = `${event.physicalAddressLine1} | Tel. ${event.phoneNumber}`;
  }

  public onChangeProcedure(event: Procedure[]): void {

    let procedure: string = '';

    event.forEach(function (item) {
      if (procedure.length === 0) {
        procedure = `${item.qsc}`;
      } else {
        procedure = `${item.qsc},${procedure}`;
      }
    });
    this.allProcedures = procedure;

    if (event.length === 0) {
      this.proceduresValidator = false;
    } else {
      this.proceduresValidator = true;
    }
  }

  public onChangeGender(event: any): void {

    this.covidData.gender = event.value;
  }

  public onSelectBirthDate(event: any): void {

    this.covidData.age = this.ageFromDateOfBirthday(event);
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {

    return moment().diff(dateOfBirth, 'years');
  }

  public onChangeCarrier(event: any): void {

    this.covidData.phoneCarrier = event.value;
  }    

  public showResponse(event: ResponseControlCaptcha) {
    let captchaToken = event.response;

    this.captchaParameter = {
      secret: ReCaptchaKeys.secretkey,
      response: captchaToken,
      remoteip: ''
    }

    this.validateCaptcha(this.captchaParameter);
  }

  public validateCaptcha(para: CaptchaParameter): void {

    this.reCaptchaService.validateCaptcha(para)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.isValidCaptcha = data;
      },
        (error: any) => {

          console.log(error);

        });
  }

  public onCaptchaExpire(): void {

    this.isValidCaptcha = false;
  } 

  public resetForm(): void {
        
    this.progressSpinner = false;
    //this.medicalPlanCardFileName.nativeElement.value = '';
    //this.medicalOrderFileName.nativeElement.value = '';
    //this.medicalPlanCardFileName = '';
    //this.medicalOrderFileName = '';
    this.labInfo = '';
    this.covidform.form.reset();
    this.covidData = { age: 0 } as CovidData;
    this.captcha.reset();
    this.isValidCaptcha = false;    
  }  

  public onChangeAuthorizedByEmail(): void {

    if (this.covidData.authorizedResultsByEmail) {

      this.emailValidator = false;
      this.emailPasswordValidator = false;

    } else {

      this.covidData.email = '';
      this.emailValidator = true;
      this.covidData.resultFilePassword = '';
      this.emailPasswordValidator = true;
    }
    
  }

  public onKey(value: string): void {

    if (value.length > 0) {

      this.emailValidator = true;

      if (this.covidData.resultFilePassword === undefined || this.covidData.resultFilePassword.length < 6) {
        this.emailPasswordValidator = false;
      } else {
        this.emailPasswordValidator = true;
      }

    } else {

      if (this.covidData.authorizedResultsByEmail) {

        this.emailValidator = false;
      }

      this.emailPasswordValidator = true;
      this.covidData.resultFilePassword = '';
    }

  }

  public onKeyPassword(value: string): void {

    if (value.length < 6) {
      this.emailPasswordValidator = false;
    } else {
      this.emailPasswordValidator = true;
    }

  }  
  
  public laMisma(): void {

    this.covidData.mailingAddressLine1 = this.covidData.physicalAddressLine1;
    this.covidData.mailingAddressLine2 = this.covidData.physicalAddressLine2;
    this.selectMailingPostalCode = this.selectPostalCode;
    this.covidData.mailingAddressState = this.covidData.physicalAddressState;
    this.covidData.mailingAddressZipZode = this.covidData.physicalZipZode;
  }

  public loadCovid19Data(patient: Patient): void {

    var today = new Date();

    this.covidData = {
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      secondLastName: patient.secondLastName,
      physicalAddressLine1: patient.physicalAddressLine1,
      physicalAddressLine2: patient.physicalAddressLine2,
      physicalCity: patient.physicalCity,
      physicalAddressState: patient.physicalAddressState,
      physicalZipZode: patient.physicalZipZode,
      mailingAddressLine1: patient.mailingAddressLine1,
      mailingAddressLine2: patient.mailingAddressLine2,
      mailingAddressCity: patient.mailingAddressCity,
      mailingAddressState: patient.mailingAddressState,
      mailingAddressZipZode: patient.mailingAddressZipZode,
      birthDate: patient.birthDate,
      phoneNumber: patient.phoneNumber,
      gender: patient.gender,
      //medicalPlanCardFileName: patient.medicalPlanCardFileName,
      //uriMedicalPlanCardFileName: patient.uriMedicalPlanCardFileName,
      //patientIdFileName: patient.patientIdFileName,
      //uriPatientIdFileName: patient.uriPatientIdFileName,
      authorizedResultsByEmail: patient.authorizedResultsByEmail,
      email: patient.email,
      resultFilePassword: patient.resultFilePassword,
      company: patient.company,
      patientId: patient.id,
      dateService: today
    } as CovidData;

    if (this.patient.birthDate !== null) {
      this.covidData.birthDate = new Date(this.patient.birthDate);
    }
    
    const filterGender = this.genderList.filter(a => a.value === this.patient.gender);

    if (filterGender !== undefined) {
      this.selectGender = filterGender[0];
    }    

    this.setEditPhysicalPostalCodes(this.patient.physicalZipZode);

    this.setEditMailingPostalCodes(this.patient.mailingAddressZipZode);

    this.autoLoadPatientDataService.SetPatientData(null);
    this.patient = undefined;
  }

  public setEditPhysicalPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];

        const filterPhysicalPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterPhysicalPostalCode !== undefined) {
          this.selectPostalCode = filterPhysicalPostalCode[0];
        }       

      });
  }

  public setEditMailingPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];        

        const filterMailingPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterMailingPostalCode !== undefined) {
          this.selectMailingPostalCode = filterMailingPostalCode[0];
        }

      });
  }

  //public previewPdfFile(): void {

  //  this.downloadPdfFile(this.patientId);

  //}

  //public downloadPdfFile(id: number): void {

  //  this.patientService.downloadPdfFile(id)
  //    .subscribe((data: any) => {

  //      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //        window.navigator.msSaveOrOpenBlob(data);
  //      } else {
  //        const objectUrl = URL.createObjectURL(data);
  //        window.open(objectUrl);
  //      }

  //    },
  //      (error: any) => {

  //        this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });

  //      });
  //}

  public logout(): void {

    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('token');
      },
        (error: any) => console.log(error));
  }

  private showMessage(severity: string, detail: string): void {

    const msg: Message = {

      severity,
      detail
    };

    this.messageService.add(msg);
  }

}
