import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

//modules
import { SharedModule } from './shared/shared.module';

//Component
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CovidDataComponent } from './covid-data/covid-data.component';
import { FormSubmitSuccessfullyComponent } from './form-submit-successfully/form-submit-successfully.component';
import { LoginComponent } from './account/login/login.component';
import { PatientComponent } from './patient/patient.component';
import { PatientDetailsComponent } from './patient/patient-details.component';
import { RegisterComponent } from './account/register/register.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { LaboratoryComponent } from './laboratory/laboratory.component';

//services
import { AuthInterceptor } from './account/auth.interceptor';
import { HttpErrorInterceptor } from './core/http-error-interceptor';
import { PostalCodeService } from './postal-code/postal-code.service';
import { AccountService } from './account/account.service';
import { LaboratoryService } from './laboratory/laboratory.service';
import { SymptomService } from './symptom/symptom.service';
import { CovidDataService } from './covid-data/covid-data.service';
import { ReCaptchaService } from './re-captcha/re-captcha.service';
import { ProcedureService } from './procedure/procedure.service';
import { UpdateMenuLoginService } from './shared/update-menu-login.service';
import { PatientService } from './patient/patient.service';
import { PatientOrdersService } from './patient/patient-orders/patient-orders.service';

//@auth0/angular-jwt
import { JwtModule } from "@auth0/angular-jwt";

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CovidDataComponent,
    FormSubmitSuccessfullyComponent,
    LoginComponent,
    PatientComponent,
    PatientDetailsComponent,
    RegisterComponent,
    ChangePasswordComponent,
    LaboratoryComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:52030', 'covid19.milaboratoriopr.com', 'covid19form.azurewebsites.net'],
        disallowedRoutes: ['http://example.com/examplebadroute/'],
      },
    }),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'test-covid19', component: HomeComponent },
      { path: 'covid19-form', component: CovidDataComponent },
      { path: 'form-submit-successfully', component: FormSubmitSuccessfullyComponent },
      { path: 'login', component: LoginComponent },
      { path: 'patient', component: PatientComponent },
      { path: 'patient-details/:id', component: PatientDetailsComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'laboratory-tests', component: LaboratoryComponent }
    ])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    AccountService,
    PostalCodeService,
    LaboratoryService,
    SymptomService,
    CovidDataService,
    ReCaptchaService,
    ProcedureService,
    UpdateMenuLoginService,
    PatientService,
    PatientOrdersService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
