// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

//core
import { PatientOrders } from './patient-orders';

@Injectable()
export class PatientOrdersService extends BaseService {

  private readonly apiUrl = 'api/patientorders';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getPatientOrders(id: number): Observable<PatientOrders[]> {

    return this.http.get<PatientOrders[]>(`${this.apiUrl}/${id}`);
  }

}
