// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Objects
import { Procedure } from './procedure';

@Injectable()
export class ProcedureService {

  private readonly apiUrl = 'api/procedure';

  public constructor(private readonly http: HttpClient) {
  }

  public getProcedures(): Observable<Procedure[]> {

    return this.http.get<Procedure[]>(this.apiUrl);
  }

}
