//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from './patient.service';
import { AccountService } from '../account/account.service';
import { AutoLoadPatientDataService } from './auto-load-patient-data.service';
import { PatientOrdersService } from './patient-orders/patient-orders.service';

//Core
import { Patient } from './patient';
import { User } from '../account/user'
import { PatientOrders } from './patient-orders/patient-orders'

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html'
})
export class PatientComponent extends OnDestroyMixin implements OnInit {

  public patients: Patient[] = [];
  public patientOrders: PatientOrders[] = [];
  public userLogged: User;
  public cols: any[];
  public loading: boolean;
  public displayOrderDialog = false;
  public patientId = 0;
  public deleteModal = false;

  constructor(private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly autoLoadPatientDataService: AutoLoadPatientDataService,
    private readonly patientOrdersService: PatientOrdersService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      this.getUserPatients();

    } else {

      this.router.navigate(['/login']);

    }

  }

  public getUserPatients(): void {

    this.loading = true;

    this.patientService.getUserPatients(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.patients = data;
        this.loadColumns();
        this.loading = false;
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public selectPatient(id: number): void {

    this.router.navigate([`/patient-details/${id}`]);
  }

  public addNewCovidOrder(id: number): void {

    const filterPatient = this.patients.filter(a => a.id === id);

    if (filterPatient !== undefined) {
      
      this.autoLoadPatientDataService.SetPatientData(filterPatient[0]);

      this.router.navigate(['/covid19-form']);
    }
  }

  public showOrders(id: number): void {

    this.displayOrderDialog = true;

    this.patientOrdersService.getPatientOrders(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: PatientOrders[]) => this.patientOrders = data,
        (error: any) => {

          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'fullName', header: 'Nombre' },
      { field: 'phoneNumber', header: 'Teléfono' },
      { field: 'company', header: 'Compañia' },
      { field: 'email', header: 'Correo electrónico' }
    ];
  }

  public showDeleteConf(id: number): void {

    this.patientId = id;
    this.deleteModal = true;
  }

  public deletePatient(): void {

    this.deleteModal = false;

    this.patientService.deletePatient(this.patientId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'patientkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'patientkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.getUserPatients();
        });

  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

}
