//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from '../account.service';
import { UpdateMenuLoginService } from '../../shared/update-menu-login.service';

//Core
import { UserLogin } from '../user-login';
import { ReturnToken } from '../return-token';
import { ForgotPassword } from './forgot-password';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent extends OnDestroyMixin implements OnInit {

  public userLogin: UserLogin;
  public loading = false;
  public expiredLoginTokenMessage = 'Login time expired!';
  public expiredLoginTokenBoolean = false;
  public displayForgotPasswordDialog = false;
  public forgotPassword: ForgotPassword;

  constructor(private readonly accountService: AccountService,
    private readonly updateMenuLoginService: UpdateMenuLoginService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    this.userLogin = { username: '', password: '' } as UserLogin;
    this.forgotPassword = {} as ForgotPassword;
    this.updateMenuLogin();
  }

   public login(): void {

     this.loading = true;

    this.accountService.login(this.userLogin)
      .pipe(untilComponentDestroyed(this))
      .subscribe((returnToken: ReturnToken) => {
        if (returnToken) {
          this.loading = false;
            //localStorage.setItem('token', returnToken.token);            
          this.accountService.storeUserData(returnToken.token, returnToken.returnUser);
          //this.updateMenuLoginService.updateMenuLogin(true);
          this.updateMenuLoginService.setExpiredLoginToken(false);
          this.updateMenuLoginService.updateMenuAdmin(returnToken.returnUser);
          this.router.navigate(['/patient']);
        }
        },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'loginkey', severity: 'error', summary: 'Error', detail: error });
        });
   }

  public updateMenuLogin(): void {

    this.updateMenuLoginService.shareExpiredLoginToken.subscribe(x => {

      this.expiredLoginTokenBoolean = x;

    });

  }

  public doForgotPassword(): void {

    this.displayForgotPasswordDialog = false;
    this.loading = true;

    this.accountService.forgotPassword(this.forgotPassword)
      .pipe(untilComponentDestroyed(this))
      .subscribe((res: any) => {

        let returnUser = res;
        this.loading = false;
        this.messageService.add({ key: 'loginkey', severity: 'success', summary: 'Change Password', detail: `La nueva contraseña fue enviada a esta (${returnUser.email}) dirección de correo electrónico.` });
      },
      (error: any) => {

        this.messageService.add({ key: 'loginkey', severity: 'error', summary: 'Error', detail: error });

      });
  }

}
