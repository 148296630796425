export class ReCaptchaKeys {

  //covid19.milaboratoriopr.com
  //covid19.labcostacaribepr.com
  public static readonly websitekey = '6Lf3XfYUAAAAAKNfsY_3e_kYE4Z9hgmTr1bgVnhK';
  public static readonly secretkey = '6Lf3XfYUAAAAAIEZL0uIe0XR0dYAssjqX-uVCVxt';

  //localhost
  //public static readonly websitekey = '6LdiwvYUAAAAADspqwuKWQZyN5INel0BkUZM62GD';
  //public static readonly secretkey = '6LdiwvYUAAAAABxy4hYPO5KRuup09hx50IbsBP4b';
}
