// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Service
import { UpdateMenuLoginService } from '../shared/update-menu-login.service';

//@auth0/angular-jwt
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Subject, of, Subscription } from "rxjs";
import { delay } from 'rxjs/operators';
import { Router } from "@angular/router";

// core
import { UserLogin } from './user-login';
import { ReturnToken } from './return-token';
import { User } from './user';
import { Register } from './register/register';
import { ChangePassword } from './change-password/change-password';
import { ForgotPassword } from './login/forgot-password';

@Injectable()
export class AccountService extends BaseService {

  private readonly apiUrl = 'api/session';
  private readonly apiUrlAccount = 'api/account';
  public userKey = 'user';
  private tokenSubscription = new Subscription()
  private timeout: any;

  public constructor(private readonly http: HttpClient,
    private readonly updateMenuLoginService: UpdateMenuLoginService,
    private readonly router: Router,
    private readonly jwtHelper: JwtHelperService) {
    super();
  }

  public login(userLogin: UserLogin): Observable<ReturnToken> {

    const body = JSON.stringify(userLogin);

    return this.http.post<ReturnToken>(`${this.apiUrl}/login`, body, { headers: this.PutPostHeaders });
  }

  public logout(): Observable<any> {

    return this.http.post(`${this.apiUrl}/logout`, undefined, { headers: this.PutPostHeaders, responseType: 'text' });
  }

  public register(newUser: Register): Observable<any> {

    return this.http.post<any>(`${this.apiUrlAccount}/register/`, newUser, { headers: this.PutPostHeaders });
  }

  public changePassword(changePass: ChangePassword): Observable<any> {

    return this.http.put<any>(`${this.apiUrlAccount}/change-password/`, changePass, { headers: this.PutPostHeaders });
  }

  public forgotPassword(model: ForgotPassword): Observable<any> {

    return this.http.put<any>(`${this.apiUrlAccount}/forgot-password/`, model, { headers: this.PutPostHeaders });
  }

  public storeUserData(token, user): void {
    this.timeout = this.jwtHelper.getTokenExpirationDate(token).valueOf() - new Date().valueOf();
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.expirationCounter(this.timeout);
  }

  public expirationCounter(timeout): void {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      console.log('EXPIRED!!');
      this.logout();
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      //this.updateMenuLoginService.updateMenuLogin(false);
      this.updateMenuLoginService.setExpiredLoginToken(true);
      this.router.navigate(['/login']);
    });
  }

  public isUserAuthenticated(): boolean {

    if (localStorage.getItem(this.userKey)) {
      // logged in so return true
      return true;
    }

    return false;
  }

  public getLoggedInUser(): User {

    let user: User = { id: '0' } as User;

    if (this.isUserAuthenticated()) {

      const userString: any = localStorage.getItem(this.userKey);

      user = JSON.parse(userString) as User;

      return user;
    }

    return user;
  }

}
