// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// core
import { CaptchaParameter } from './captcha-parameter';

@Injectable()
export class ReCaptchaService extends BaseService {

  private readonly apiUrl = 'api/recaptcha';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public validateCaptcha(para: CaptchaParameter): Observable<boolean> {

    return this.http.post<boolean>(this.apiUrl, para, { headers: this.PutPostHeaders });
  }
    
}
