import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//PrimeNg
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { CaptchaModule } from 'primeng/captcha';
import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset';
//import { ChipsModule } from 'primeng/chips';
//import { DataGridModule } from 'primeng/datagrid';
//import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
//import { GrowlModule } from 'primeng/growl';
import { InputMaskModule } from 'primeng/inputmask';
//import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
//import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
//import { SelectButtonModule } from 'primeng/selectbutton';
//import { SidebarModule } from 'primeng/sidebar';
import { SpinnerModule } from 'primeng/spinner';
//import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
//import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    CardModule,
    CheckboxModule,
    CaptchaModule,
    ToastModule,
    FieldsetModule,
    //ChipsModule,
    InputTextModule,
    //InputSwitchModule,
    //DataViewModule,
    ButtonModule,
    DialogModule,
    InputMaskModule,
    InputNumberModule,
    FileUploadModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    EditorModule,
    PasswordModule,
    PanelModule,
    //DataGridModule,
    CarouselModule,
    CalendarModule,
    ChartModule,
    DropdownModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TableModule,
    //SelectButtonModule,
    ScrollPanelModule,
    //SidebarModule,
    //ToolbarModule,
    TooltipModule,
    SpinnerModule,
    ListboxModule,
    RadioButtonModule,
    //StepsModule,
    //OverlayPanelModule
  ],

  declarations: [
    //NavigationComponent,
  ],

  providers: [
    ConfirmationService,
    MessageService
  ],

  exports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    CardModule,
    CheckboxModule,
    CaptchaModule,
    ToastModule,
    FieldsetModule,
    //ChipsModule,
    InputTextModule,
    //InputSwitchModule,
    //DataViewModule,
    ButtonModule,
    DialogModule,
    InputMaskModule,
    InputNumberModule,
    FileUploadModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    EditorModule,
    PasswordModule,
    PanelModule,
    //DataGridModule,
    CarouselModule,
    CalendarModule,
    ChartModule,
    DropdownModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TableModule,
    //SelectButtonModule,
    ScrollPanelModule,
    //SidebarModule,
    //ToolbarModule,
    TooltipModule,
    SpinnerModule,
    ListboxModule,
    RadioButtonModule,
    //StepsModule,
    //OverlayPanelModule
  ]
})

export class SharedModule { }
