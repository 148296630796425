//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { AccountService } from '../account.service';

//Core
import { Register } from './register';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  public title = 'Register';
  public newUser: Register;;
  public FormIsValid = true;
  public isCreatedUser = false;

  constructor(private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    this.newUser = {} as Register;
  }

  public register(): void {

    if (this.newUser.password !== this.newUser.confirmPassword) {

      this.FormIsValid = false;

      this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: 'Password and confirm password must match.' });
    }
    else {

      this.FormIsValid = true;

      //this.newUser.userName = this.newUser.email.substring(0, this.newUser.email.lastIndexOf("@"));
      this.newUser.userName = this.newUser.email;

      this.accountService.register(this.newUser)
        .subscribe((res: any) => {

          this.isCreatedUser = true;
          this.messageService.add({ key: 'registerkey', severity: 'success', summary: 'Register', detail: 'Register success.' });          
        },
          (error: any) => {

            this.isCreatedUser = false;
            this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public onCloseToast(): void {

    if (this.isCreatedUser === true) {
      this.router.navigate(['/login']);
    }
  }

}
