// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Objects
import { Symptom } from './symptom';

@Injectable()
export class SymptomService {

  private readonly apiUrl = 'api/symptom';

  public constructor(private readonly http: HttpClient) {
  }

  public getSymptoms(): Observable<Symptom[]> {

    return this.http.get<Symptom[]>(this.apiUrl);
  }

}
``
