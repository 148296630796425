//Angular
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PatientService } from './patient.service';
import { AccountService } from '../account/account.service';
import { PostalCodeService } from '../postal-code/postal-code.service';

//Core
import { Patient } from './patient';
import { PostalCode } from '../postal-code/postal-code';
import { OperationResult } from '../core/operation-result';
import { User } from '../account/user'

//Primeng
import { MessageService } from 'primeng/api';

interface Gender {
  label: string;
  value: string;
}

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html'
})
export class PatientDetailsComponent extends OnDestroyMixin implements OnInit {

  public title = 'PATIENT DETAILS';
  public patient: Patient;
  public userLogged: User;
  public selectPostalCode: PostalCode;
  public postalCodeSearchResults: PostalCode[];
  public selectMailingPostalCode: PostalCode;
  public genderList: Gender[] = [];
  public selectGender: Gender;
  public progressSpinner = false;
  public emailValidator = true;
  public emailPasswordValidator = true;
  public imgMedicalPlanCard: any;
  public imgPatientId: any;
  public isNewPatient = false;
  //public isPdfFile = false;

  constructor(private readonly patientService: PatientService,
    private readonly accountService: AccountService,
    private readonly postalCodeService: PostalCodeService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _location: Location) {
    super();

    this.genderList = [
      { label: 'Masculino', value: 'Masculino' },
      { label: 'Femenino', value: 'Femenino' },
      { label: 'Otros', value: 'Otros' }
    ];

  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.patient = {} as Patient;

      const id = this.route.snapshot.params['id'];

      this.userLogged = this.getlocalUser();

      if (id === '0') {

        this.title = 'AÑADA NUEVO PACIENTE';
        this.isNewPatient = true;
      }
      else {

        this.title = `EDITAR PACIENTE: ${id}`;
        this.isNewPatient = false;
        this.getPatient(id);
      }

    } else {

      this.router.navigate(['/account/login']);
    }

  }

  public onSubmit(): void {

    this.progressSpinner = true;

    this.patient.userId = this.userLogged.id;
    this.patient.physicalCity = this.selectPostalCode.city;
    this.patient.mailingAddressCity = this.selectMailingPostalCode.city;

    if (this.isNewPatient === true) {

      this.patientService.postPatient(this.patient)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (data) => {

            this.patient = data;
          },
          (error: string) => {

            this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });

            this.progressSpinner = false;
          },
          () => {

            this.onUploadMedicalPlanCard(this.patient.id.toString());

          });
    } else {

      this.patientService.putPatient(this.patient)
        .subscribe((res: any) => {

          this.router.navigate([`/patient`]);
        },
          (error: any) => {

            this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });

          },
          () => {

            this.onUploadMedicalPlanCard(this.patient.id.toString());

          });

    }
  }

  public onUploadMedicalPlanCard(patientId: string): void {

    this.progressSpinner = true;

    if (this.imgMedicalPlanCard !== undefined) {

      let formData = new FormData();

      formData.append(this.imgMedicalPlanCard.name, this.imgMedicalPlanCard);
      formData.append(`fileType${this.imgMedicalPlanCard.name}`, 'MPC');
      formData.append('patientId', patientId);

      this.patientService.postImagesPatient(formData)
        .subscribe((res: OperationResult) => {

          if (res.succeeded === true) {

            this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Success', detail: res.message });
          } else {

            this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: res.message });
          }
        },
          (error: any) => {

            this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.onUploadPatientId(patientId);

          });
    } else {

      this.onUploadPatientId(patientId);
    }
  }

  public onUploadPatientId(patientId: string): void {

    this.progressSpinner = true;

    if (this.imgPatientId !== undefined) {

      let formData = new FormData();

      formData.append(this.imgPatientId.name, this.imgPatientId);
      formData.append(`fileType${this.imgPatientId.name}`, 'PId');
      formData.append('patientId', patientId);

      this.patientService.postImagesPatient(formData)
        .subscribe((res: OperationResult) => {

          if (res.succeeded === true) {

            this.messageService.add({ key: 'patientdetailskey', severity: 'success', summary: 'Success', detail: res.message });
          } else {

            this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: res.message });
          }
        },
          (error: any) => {

            this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.router.navigate(['/patient']);
            this.progressSpinner = false;
          });

    } else {

      this.router.navigate(['/patient']);
      this.progressSpinner = false;
    }
  }

  public getPatient(id: number): void {

    this.patientService.getPatient(id)
      .subscribe((res: any) => this.patient = res as Patient,
        (error: any) => {

          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          if (this.isNewPatient === false) {

            if (this.patient.birthDate !== null) {
              this.patient.birthDate = new Date(this.patient.birthDate);
            }

            const filterGender = this.genderList.filter(a => a.value === this.patient.gender);

            if (filterGender !== undefined) {
              this.selectGender = filterGender[0];
            }

            if (this.patient !== null && this.isNewPatient === false) {

              this.setEditPhysicalPostalCodes(this.patient.physicalZipZode);

              this.setEditMailingPostalCodes(this.patient.mailingAddressZipZode);

            }

          }

        });
  }  

  public setEditPhysicalPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];

        const filterPhysicalPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterPhysicalPostalCode !== undefined) {
          this.selectPostalCode = filterPhysicalPostalCode[0];
        }

      });
  }

  public setEditMailingPostalCodes(zipCode: string): void {

    this.postalCodeService.searchByZipCode(zipCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        const postalCodeSearchResults = data as PostalCode[];

        const filterMailingPostalCode = postalCodeSearchResults.filter(a => a.code === zipCode);

        if (filterMailingPostalCode !== undefined) {
          this.selectMailingPostalCode = filterMailingPostalCode[0];
        }

      });
  }

  // search  postal codes
  public searchPostalCodes(event: any): void {

    this.postalCodeService.searchPostalCodes(event.query)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.postalCodeSearchResults = data;
      });
  }

  // set  postal code information
  public onPostalCodeSelection(postalCode: PostalCode): void {

    this.selectPostalCode = postalCode;
    this.patient.physicalAddressState = postalCode.state;
    this.patient.physicalZipZode = postalCode.code;
  }

  // clear postal code information
  public clearSelectedPostalCode(): void {

    this.selectPostalCode = undefined;
  }

  // set mailing  postal code information
  public onMailingPostalCodeSelection(postalCode: PostalCode): void {

    this.selectMailingPostalCode = postalCode;
    this.patient.mailingAddressState = postalCode.state;
    this.patient.mailingAddressZipZode = postalCode.code;
  }

  // clear postal code information
  public clearMailingSelectedPostalCode(): void {

    this.selectMailingPostalCode = undefined;
  }

  public onChangeGender(event: any): void {

    this.patient.gender = event.value;
  }

  public onChangeAuthorizedByEmail(): void {

    if (this.patient.authorizedResultsByEmail) {

      this.emailValidator = false;
      this.emailPasswordValidator = false;

    } else {

      this.patient.email = '';
      this.emailValidator = true;
      this.patient.resultFilePassword = '';
      this.emailPasswordValidator = true;
    }

  }

  public onKey(value: string): void {

    if (value.length > 0) {

      this.emailValidator = true;

      if (this.patient.resultFilePassword === undefined || this.patient.resultFilePassword.length < 6) {
        this.emailPasswordValidator = false;
      } else {
        this.emailPasswordValidator = true;
      }

    } else {

      if (this.patient.authorizedResultsByEmail) {

        this.emailValidator = false;
      }

      this.emailPasswordValidator = true;
      this.patient.resultFilePassword = '';
    }

  }

  public onKeyPassword(value: string): void {

    if (value.length < 6) {
      this.emailPasswordValidator = false;
    } else {
      this.emailPasswordValidator = true;
    }

  }

  public eventMedicalPlanCard(fileInput: any): void {

    //this.imgMedicalPlanCard = fileInput.target.files[0];
    this.imgMedicalPlanCard = fileInput.files[0];
  }

  public eventPatientId(fileInput: any): void {

    //this.imgPatientId = fileInput.target.files[0];
    this.imgPatientId = fileInput.files[0];
  }

  public laMisma(): void {

    this.patient.mailingAddressLine1 = this.patient.physicalAddressLine1;
    this.patient.mailingAddressLine2 = this.patient.physicalAddressLine2;
    this.selectMailingPostalCode = this.selectPostalCode;
    this.patient.mailingAddressState = this.patient.physicalAddressState;
    this.patient.mailingAddressZipZode = this.patient.physicalZipZode;
  }

  public previewPdfFile(): void {

    this.downloadPdfFile(this.patient.id);

  }

  public downloadPdfFile(id: number): void {

    this.patientService.downloadPdfFile(id)
      .subscribe((data: any) => {

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(data);
        } else {
          const objectUrl = URL.createObjectURL(data);
          window.open(objectUrl);
        }

      },
        (error: any) => {

          this.messageService.add({ key: 'patientdetailskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    return userLogged;
  }
  
}
