//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Core
import { ChangePassword } from './change-password';
import { User } from '../user';

//Service
import { AccountService } from '../account.service';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  public title = 'Cambio de Contraseña';
  public userChangePassword: ChangePassword;
  public userLogged: User;
  public FormIsValid = true;

  constructor(private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) { }

  public ngOnInit(): void {

    this.userChangePassword = {} as ChangePassword;
    this.getUser();
  }

  public changePassword(): void {
    if (this.userChangePassword.newpassword !== this.userChangePassword.confirmpassword) {
      this.FormIsValid = false;
    }
    else {
      this.FormIsValid = true;
      this.userChangePassword.userName = this.userLogged.userName;
      this.userChangePassword.email = this.userLogged.email;
      this.doChangePassword();
    }
  }

  public doChangePassword(): void {

    this.accountService.changePassword(this.userChangePassword)
      .subscribe((res: any) => {

        let returnUser = res;

        this.messageService.add({ key: 'changepasswordkey', severity: 'success', summary: 'Change Password', detail: `Dear ${returnUser.userName}, please login with your credentials` });

        this.logout();
        this.router.navigate(['/login']);
      },
        (error: any) => {

          this.messageService.add({ key: 'changepasswordkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public isUserLoggedIn(): boolean {
    return this.accountService.isUserAuthenticated();
  }

  public getUser(): void {
    if (this.isUserLoggedIn()) {
      this.userLogged = this.accountService.getLoggedInUser();
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  public logout(): void {
    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      },
        (error: any) => console.log(error));
  }

}
