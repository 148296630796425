// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SummaryDataService {

  private confirmationNumber = new BehaviorSubject<string>('00-000');
  public shareConfirmationNumber = this.confirmationNumber.asObservable();

  private returnValueMO = new BehaviorSubject<string>('');
  public shareReturnValueMO = this.returnValueMO.asObservable();

  private successMO = new BehaviorSubject<boolean>(false);
  public shareSuccessMO = this.successMO.asObservable();
    
  private returnValueMPC = new BehaviorSubject<string>('');
  public shareReturnValueMPC = this.returnValueMPC.asObservable();

  private successMPC = new BehaviorSubject<boolean>(false);
  public shareSuccessMPC = this.successMPC.asObservable();

  private returnValuePId = new BehaviorSubject<string>('');
  public shareReturnValuePId = this.returnValuePId.asObservable();

  private successPId = new BehaviorSubject<boolean>(false);
  public shareSuccessPId = this.successPId.asObservable();

  public constructor() {}

  public setConfirmationNumber(textValue: string): void {

    this.confirmationNumber.next(textValue);
  }

  public setReturnValueMO(textValue: string): void {

    this.returnValueMO.next(textValue);
  }

  public setSuccessMO(value: boolean): void {

    this.successMO.next(value);
  }

  public setReturnValueMPC(textValue: string): void {

    this.returnValueMPC.next(textValue);
  }

  public setSuccessMPC(value: boolean): void {

    this.successMPC.next(value);
  }

  public setReturnValuePId(textValue: string): void {

    this.returnValuePId.next(textValue);
  }

  public setSuccessPId(value: boolean): void {

    this.successPId.next(value);
  }
}
