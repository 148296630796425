//Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';

//Service
import { AccountService } from '../account/account.service';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  constructor(private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) { }

  public navigateWithLogin(): void {

    if (this.isUserLoggedIn()) {

      this.router.navigate(['/patient']);
    } else {

      this.router.navigate(['/login']);
    }

  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
