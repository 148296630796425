//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Service
import { AccountService } from '../account/account.service';
import { UpdateMenuLoginService } from '../shared/update-menu-login.service';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  isExpanded = false;
  public isAdmin = false;

  constructor(private readonly accountService: AccountService,
    private readonly updateMenuLoginService: UpdateMenuLoginService,
    private readonly messageService: MessageService,
    private readonly router: Router,) { }

  public ngOnInit(): void {

    this.updateMenuAdmin();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public logout(): void {

    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        this.updateMenuLoginService.updateMenuAdmin(undefined);

        // this.router.navigate(['/account/login']);
      },
        (error: any) => {

          this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public isUserLoggedIn(): boolean {    

    return this.accountService.isUserAuthenticated();
  }  

  public updateMenuAdmin(): void {

    this.updateMenuLoginService.shareAdmin.subscribe(x => {

      this.isAdmin = x;

    });

  }

}
