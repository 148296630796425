//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { LaboratoryService } from './laboratory.service';
import { AccountService } from '../account/account.service';

// Objects
import { LaboratoriesTests } from './laboratory';

// Moment
import * as moment from 'moment';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html',
})
export class LaboratoryComponent extends OnDestroyMixin implements OnInit {

  public title = 'Covid 19 Tests';
  public laboratoriesTests: LaboratoriesTests[] = [];
  public fromDate: Date;
  public toDate: Date;
  public progressSpinner = false;

  public constructor(private readonly laboratoryService: LaboratoryService,
    private readonly messageService: MessageService,
    private readonly accountService: AccountService,
    private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      var today = new Date();
      this.fromDate  = new Date(today);
      this.toDate = new Date(today);

    } else {

      this.router.navigate(['/login']);
    }
   
  }

  public getLaboratoriesTests(): void {

    if (moment(this.fromDate).isSameOrBefore(this.toDate)) {

      this.progressSpinner = true;

      this.laboratoryService.getLaboratoriesTests(this.fromDate, this.toDate)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => {

          this.laboratoriesTests = data as LaboratoriesTests[];
          this.progressSpinner = false;
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'laboratorykey', severity: 'error', summary: 'Error', detail: error });
          });      

    } else {

      this.messageService.add({ key: 'laboratorykey', severity: 'error', summary: 'Error', detail: 'Ending date must be greater than beginning date.' });
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
