import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

//Core
import { User } from '../account/user';

@Injectable()
export class UpdateMenuLoginService {

  //public isLogin = new BehaviorSubject<boolean>(false);
  //public shareLogin = this.isLogin.asObservable();

  public isAdmin = new BehaviorSubject<boolean>(false);
  public shareAdmin = this.isAdmin.asObservable();

  private expiredLoginToken = new BehaviorSubject<boolean>(false);
  public shareExpiredLoginToken = this.expiredLoginToken.asObservable();

  constructor() { }

  //public updateMenuLogin(newLogin: boolean): void {

  //  this.isLogin.next(newLogin);
  //}

  public updateMenuAdmin(userLogged: User): void {

    if (userLogged === undefined) {

      this.isAdmin.next(false);

    } else {

      const userRoles = userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');

      if (userRoles !== undefined) {
        this.isAdmin.next(true);
      }
      else {
        this.isAdmin.next(false);
      }

    }
  }

  public setExpiredLoginToken(booleanValue: boolean): void {

    this.expiredLoginToken.next(booleanValue);
  }

}
