// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Objects
import { PostalCode } from './postal-code';

@Injectable()
export class PostalCodeService {

  private readonly apiUrl = 'api/postalcode';

  public constructor(private readonly http: HttpClient) {
  }

  public getPostalCodes(): Observable<PostalCode[]> {

    return this.http.get<PostalCode[]>(this.apiUrl);
  }

  public searchPostalCodes(searchText: string): Observable<PostalCode[]> {

    const url = `${this.apiUrl}/search/postal-codes/${searchText}`;

    return this.http.get<PostalCode[]>(url);
  }

  public searchByZipCode(zipCode: string): Observable<PostalCode[]> {

    const url = `${this.apiUrl}/search-zipcode/postal-codes/${zipCode}`;

    return this.http.get<PostalCode[]>(url);
  }

}
