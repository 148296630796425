// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Core
import { Patient } from './patient';

@Injectable({ providedIn: 'root' })
export class AutoLoadPatientDataService {

  private patientData = new BehaviorSubject<Patient>(null);
  public sharePatientData = this.patientData.asObservable();

  constructor() { }

  public SetPatientData(patient: Patient): void {

    this.patientData.next(patient);
  }

}
