// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Objects
import { Laboratory, DateParams, LaboratoriesTests } from './laboratory';

@Injectable()
export class LaboratoryService {

  private readonly apiUrl = 'api/laboratory';

  public constructor(private readonly http: HttpClient) {
  }

  public getLaboratories(): Observable<Laboratory[]> {

    return this.http.get<Laboratory[]>(this.apiUrl);
  }

  public getLaboratoriesTests(fromDate: Date, toDate: Date): Observable<LaboratoriesTests[]> {

    const dateParams: DateParams = {
      fromDate: fromDate,
      toDate: toDate
    };

    return this.http.post<LaboratoriesTests[]>(`${this.apiUrl}/laboratory-tests`, dateParams);
  }

}
